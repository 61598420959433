import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [];

export const dictionary = {
		"/(public)": [25,[10]],
		"/(public)/auth/federated-login/callback": [26],
		"/(public)/auth/forgot-password": [27,[10,11]],
		"/(public)/auth/magic-link": [28],
		"/(public)/auth/register": [29,[10,11]],
		"/(public)/auth/register/confirm": [30,[10,11]],
		"/(public)/auth/register/info": [31,[10,11]],
		"/(public)/auth/reset-password": [32,[10,11]],
		"/(public)/auth/signin": [33,[10,11]],
		"/(public)/auth/signout": [34,[10,11]],
		"/(public)/auth/signup": [35,[10,11]],
		"/(public)/auth/signup/info": [36],
		"/(public)/auth/signup/secure": [37],
		"/(protected)/(dashboard)/e/[eid]": [12,[2,3,4]],
		"/(protected)/(dashboard)/e/[eid]/(settings)/collaborators": [13,[2,3,5]],
		"/(protected)/(dashboard)/e/[eid]/m/[mid]/q/[qid]": [15,[2,3,4]],
		"/(protected)/(dashboard)/e/[eid]/(settings)/settings": [14,[2,3,5]],
		"/(protected)/(dashboard)/home": [16,[2,3]],
		"/(protected)/(viewer)/n/[eid]": [23,[2,7,8]],
		"/(protected)/(viewer)/p/[eid]": [24,[2,7,9]],
		"/(public)/try": [38,[10]],
		"/(protected)/(dashboard)/u": [17,[2,3]],
		"/(protected)/(dashboard)/u/password": [18,[2,3]],
		"/(protected)/(dashboard)/u/profile": [19,[2,3]],
		"/(protected)/(dashboard)/w/[wid]": [20,[2,3,6]],
		"/(protected)/(dashboard)/w/[wid]/members": [21,[2,3,6]],
		"/(protected)/(dashboard)/w/[wid]/settings": [22,[2,3,6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';