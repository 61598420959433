import * as Sentry from '@sentry/sveltekit';

import { PUBLIC_APP_ENV, PUBLIC_RELEASE_VERSION } from '$env/static/public';
import { browser, dev } from '$app/environment';

import { handleErrorWithSentry } from '@sentry/sveltekit';
import posthog from 'posthog-js';

Sentry.init({
	dsn: 'https://1a03509d88fb4703a6f2cbea47f78d17@o1394219.ingest.sentry.io/4505451604803584',
	sampleRate: PUBLIC_APP_ENV === 'production' ? 1.0 : 0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	integrations: [
		Sentry.replayIntegration(),
		Sentry.browserTracingIntegration(),
		posthog.sentryIntegration({
			organization: 'ritual-k1',
			projectId: 4505451604803584
		})
	],
	environment: PUBLIC_APP_ENV,
	release: PUBLIC_APP_ENV === 'production' ? 'ritual-web@' + PUBLIC_RELEASE_VERSION : undefined,

	tracesSampleRate: PUBLIC_APP_ENV === 'production' ? 1.0 : 0,
	tracePropagationTargets: [
		'localhost',
		/^https:\/\/api\.ritualql\.com/,
		/^https:\/\/ai\.ritual\.technology/
	]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();

// Enable MSW in development
if (dev && browser && import.meta.env.VITE_MSW_ENABLED === 'true') {
	const { worker } = await import('./msw/worker');

	await worker.start({ onUnhandledRequest: 'bypass' }).catch(console.warn);
}
